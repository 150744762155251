import VueRouter, {NavigationGuard, NavigationGuardNext, Route as BaseRoute} from "vue-router";
import {
    _RouteConfigBase,
    Component,
    Dictionary,
    Location,
    RoutePropsFunction,
    RouterOptions as VueRouterOptions
} from "vue-router/types/router";
import Kernel, {KernelConstructor, Middleware, ResolvableMiddleware} from "@/app/Http/Kernel";

export interface Route extends BaseRoute {
    middleware?: ResolvableMiddleware[]
}

export interface ResolvedRoute {
    location: Location,
    route: Route,
    href: string,
    normalizedTo: Location,
    resolved: Route,
}

export interface RouteConfigMeta {
    middleware?: string[] | NavigationGuard[]

    [key: string]: any
}

export interface RouteConfig extends _RouteConfigBase {
    component?: Component
    components?: Dictionary<Component>
    props?: boolean | Object | RoutePropsFunction | Dictionary<boolean | Object | RoutePropsFunction>
    meta?: RouteConfigMeta
    prefix?: string
}

export interface RouterOptions extends VueRouterOptions {
    routes?: RouteConfig[]
    kernel?: KernelConstructor
}

const fixName = (route: RouteConfig, parent?: RouteConfig): RouteConfig => {
    const parentPrefix = parent?.prefix || '';

    if (route.name == null && route.prefix != null) {
        route.name = route.prefix.replace(/\.$/m, '');
    } else if (route.name == null) {
        return route;
    }

    if (route.prefix != null && !route.prefix.startsWith(route.name)) {
        route.name = [route.prefix, route.name].join('');
    }

    if (!route.name.startsWith(parentPrefix)) {
        route.name = [parentPrefix, route.name].join('');
    }

    return route;
}

const fixRoute = (route: RouteConfig): RouteConfig => {
    if (route.children != null) {
        route.children = route.children.map((childRoute: RouteConfig): RouteConfig => {
            return fixRoute(fixName(childRoute, route));
        });
    }

    return fixName(route);
};

const fixRouteNames = (options: RouterOptions): RouterOptions => {
    if (options.routes == null) {
        return options;
    }

    options.routes = options.routes.map(fixRoute)

    return options;
}

export default class Router extends VueRouter {
    protected kernel: Kernel;

    constructor(options: RouterOptions = {}) {
        options = fixRouteNames(options);

        super(Object.assign({mode: 'history'}, options));

        this.kernel = options.kernel != null ? new options.kernel(this) : new Kernel(this);

        this.beforeEach((to: Route, _, next: NavigationGuardNext) => {
            this.kernel.handle(to, next);
        });
    }

    public static create(options: RouterOptions = {}) {
        return new Router(options);
    }

    public addDefaultMiddleware(middleware: Middleware | string): Router {
        this.kernel.addDefaultMiddleware(middleware);

        return this;
    }

    public addGroupMiddlewareAlias(name: string, group: Middleware[]): Router {
        this.kernel.addGroupMiddlewareAlias(name, group);

        return this;
    }

    public addMiddlewareAlias(name: string, middleware: Middleware): Router {
        this.kernel.addMiddlewareAlias(name, middleware);

        return this;
    }
}