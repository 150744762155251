import '@/resources/scss/base.scss';
import Vue, {CreateElement} from 'vue'
import store from '@/app/store';
import router from '@/routes';
import BaseLayout from '@/resources/views/layouts/BaseLayout.vue';
import OverlayPlugin from "@/app/plugins/Overlay";
import Flashes from "@/app/plugins/Flashes";
import Client from "@/app/Http/Client";
import {AxiosError} from 'axios';

Vue.config.productionTip = false;

Vue.use(OverlayPlugin);
Vue.use(Flashes);

const root = new Vue({
    store,
    router,
    el: '#app',
    render: (h: CreateElement) => h(BaseLayout),
    data: {
        sidebar: true,
    }
});

Client.baseUrl = process.env.VUE_APP_API_URL;
Client.onUnprocessableEntity = async (error: AxiosError) => {
    if (
        !error.response
        || !error.response.data
        || !error.response.data.errors
        || Object.keys(error.response.data.errors).length === 0
    ) {
        root.flash('Whoops. Something went wrong.');
        return;
    }

    const laravelError = error.response.data;

    Object.values(laravelError.errors as object)
        .forEach((grouped: string[]) => {
            grouped.forEach((error: string) => {
                root.flash(error);
            });
        });
};
