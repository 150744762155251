import kebabCase from 'lodash/kebabCase';
import {PluginObject, VueConstructor} from "vue";

export type MessageEvent = {
    message: string;
    timeout: number;
}

export type MixinMethods = {
    flash(msg: string, timeout?: number): void
};

export type Message = {
    time: number
    timeout: number
    element: HTMLElement
};

export type MessageList = {
    [key: string]: Message
};

const createMessageWrapperElement = (): HTMLElement => {
    const element = document.createElement('div');
    element.classList.add('messages');

    return element;
}
const createMessageElement = (message: string): HTMLElement => {
    const element = document.createElement('div');
    element.classList.add('message');
    element.innerHTML = message;

    return element;
}

export default {
    install(vue: VueConstructor) {
        vue.mixin(vue.extend<{}, MixinMethods, {}, {}>({
            methods: {
                flash(msg: string, timeout: number = 1500) {
                    this.$root.$emit('flash', {
                        timeout,
                        message: msg,
                    } as MessageEvent);
                },
            },
        }));

        vue.mixin(vue.extend<{ messages?: MessageList }, { removeMessage: (key: string) => void }, {}, {}>({
            data() {
                if (this.$parent == null) {
                    return {
                        messages: {}
                    };
                }

                return {};
            },
            mounted() {
                if (this.$parent != null) {
                    return;
                }

                const messageWrapper = createMessageWrapperElement();
                this.$el.appendChild(messageWrapper);
                Object.assign(this.$refs, {messageWrapper});

                this.removeMessage = (key: string) => {
                    clearTimeout(this.messages![key].timeout);
                    (this.$refs.messageWrapper as HTMLElement).removeChild(this.messages![key].element);
                    delete this.messages![key];
                }

                Object.assign(this.$data, {messages: []});
                this.$root.$on('flash', (event: MessageEvent) => {
                    const key = kebabCase(event.message);
                    const element = createMessageElement(event.message);

                    if (this.messages!.hasOwnProperty(key)) {
                        this.removeMessage(key);
                    }

                    this.messages![key] = {
                        element,
                        time: event.timeout,
                        timeout: setTimeout(() => {
                            this.removeMessage(key);
                        }, event.timeout),
                    };

                    (this.$refs.messageWrapper as HTMLElement).appendChild(this.messages![key].element);
                });
            },
        }));
    },
} as PluginObject<undefined>;