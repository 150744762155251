import axios from "axios";
import {NavigationGuardNext} from "vue-router";
import Client from "@/app/Http/Client";
import {Route} from "@/app/Http/Router";

export default function auth(to: Route, from: Route, next: NavigationGuardNext<Vue>) {

    if (to.query.token && typeof to.query.token === 'string') {
        localStorage.setItem('token', to.query.token);
    }

    Client.post('/azure/token/validate').then(function () {
        next();
    })
}
