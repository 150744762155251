import Vue from 'vue';
import VueRouter from 'vue-router';
import Router from "@/app/Http/Router";
import auth from "@/app/Http/middleware/Auth"


Vue.use(VueRouter);

const router = Router.create(
    {
        mode: 'history',
        routes: [
            {
                path: '/',
                redirect: '/documenten'
            },
            {
                path: '/auth',
                redirect: '/documenten'
            },
            {
                path: '/documenten',
                name: 'documents.index',
                components: {
                    default: () => import(/* webpackChunkName: "documents" */ ("@/resources/views/documents/Index.vue")),
                    sidebar: () => import(/* webpackChunkName: "sidebar" */ "@/resources/components/Sidebar.vue"),
                },

            children: [
                {
                    path: ':relationId',
                    alias: 'overig',
                    name: 'documents.detail',
                    components: {
                        default: () => import(/* webpackChunkName: "documents" */ ("@/resources/views/documents/Show.vue")),
                        sidebar: () => import(/* webpackChunkName: "sidebar" */ "@/resources/components/Sidebar.vue"),
                    },
                    children: [
                        {
                            path: 'aanmaken',
                            name: 'documents.create',
                            components: {
                                default: () => import(/* webpackChunkName: "store" */ "@/resources/views/categories/Create.vue"),
                            }
                        }
                    ]
                },
                {
                    path: ':relationId/:categoryId',
                    alias: 'categorieen',
                    name: 'documents.category',
                    components: {
                        default: () => import(/* webpackChunkName: "documents" */ ("@/resources/views/documents/Show.vue")),
                        sidebar: () => import(/* webpackChunkName: "sidebar" */ "@/resources/components/Sidebar.vue"),
                    },
                },
            ]
        },
        {
            path: '/gebruikers',
            name: 'users.index',
            components: {
                default: () => import(/* webpackChunkName: "users" */ ("@/resources/views/users/Index.vue")),
                sidebar: () => import(/* webpackChunkName: "sidebar" */ "@/resources/components/Sidebar.vue"),
            },

            },
        ],
    }
);
router.beforeEach(auth);
export default router;
