import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";

export type OnForbiddenCallback = (response: AxiosResponse) => Promise<void>
export type OnUnauthorizedCallback = (response: AxiosResponse) => Promise<void>
export type OnUnprocessableEntityCallback = (error: AxiosError) => Promise<void>
export type OnInternalServerErrorCallback = (error: AxiosError) => Promise<void>

function createAxiosInstance(): AxiosInstance {
    const instance = axios.create({baseURL: process.env.VUE_APP_API_URL, withCredentials: true});
    instance.interceptors.request.use((requestConfig: AxiosRequestConfig) => {
        if (!requestConfig.headers) {
            requestConfig.headers = {};
        }
        requestConfig.headers.token = localStorage.getItem('token');
        return requestConfig;
    })
    instance.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
            if (!error.response) {
                return Promise.reject(error);
            }

            switch (error.response.status) {
                case 401:
                    Client.onUnauthorized(error.response);
                    break;
                case 403:
                    Client.onForbidden(error.response);
                    break;
                case 422:
                    Client.onUnprocessableEntity(error);
                    break;
                case 500:
                    Client.onInternalServerError(error);
                    break;
            }

            return Promise.reject(error);
        }
    );

    return instance;
}

async function loginWithAzure() {
    let response = Client.get(`azure/login`);
    response.then(function (result) {
        //@ts-ignore
        location.href = result.data;
    });
}

export default class Client {
    public static onForbidden: OnForbiddenCallback = loginWithAzure;
    public static onUnauthorized: OnUnauthorizedCallback = loginWithAzure;
    public static onUnprocessableEntity: OnUnprocessableEntityCallback = async (res) => {
    };
    public static onInternalServerError: OnInternalServerErrorCallback = async (res) => {
    };
    private static axios: AxiosInstance = createAxiosInstance();

    public static set baseUrl(baseUrl: string) {
        Client.axios.defaults.baseURL = baseUrl;
    }

    public static get baseUrl(): string {
        return Client.axios.defaults.baseURL!;
    }

    public static get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('get', url, config);
    }

    public static head<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('head', url, config);
    }

    public static options<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('options', url, config);
    }

    public static post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('post', url, data, config);
    }

    public static patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        if (data instanceof FormData) {
            data.append('_method', 'patch');
        } else {
            data._method = 'patch';
        }

        return this.request<T>('post', url, data, config);
    }

    public static put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        if (data instanceof FormData) {
            data.append('_method', 'put');
        } else {
            data._method = 'put';
        }

        return this.request<T>('post', url, data, config);
    }

    public static delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.request<T>('post', url, {_method: 'delete'}, config);
    }

    private static async csrf(): Promise<void> {
        await this.axios.get('/sanctum/csrf-cookie');
    }

    private static request<T>(method: string, ...args: any[]): Promise<AxiosResponse<T>> {
        return new Promise<AxiosResponse<T>>((resolve, reject) => {
            // @ts-ignore
            this.axios[method]<T>(...args)
                .then(resolve)
                .catch(reject);
        });
    }
}
