import Vue from 'vue';
import {PluginObject} from "vue/types/plugin";

export enum OverlayEvents {
    Hide = 'overlay.hide',
    Closed = 'overlay.closed',
    ShowComponent = 'overlay.show-component',
    ShowElement = 'overlay.show-element',
}

export const MethodsOnly = Vue.extend({
    methods: {
        showOnOverlay(): void {
            this.$root.$emit(OverlayEvents.ShowComponent, this);
        },

        showElementOnOverlay(el: HTMLElement): void {
            this.$root.$emit(OverlayEvents.ShowElement, this);
        },

        hideOverlay(): void {
            this.$root.$emit(OverlayEvents.Hide);
        },

        onOverlayClosed(callback: Function): void {
            this.$root.$on(OverlayEvents.Closed, callback);
        },
    },
});

export const ShowsInOverlayMixin = Vue.extend<{}, { showOnOverlay: () => void }, {}>({
    mounted() {
        this.showOnOverlay();
        this.$on(OverlayEvents.Closed, () => {
            this.$router.back();
        });
    },
});

export default {
    install(vue: typeof Vue): void {
        vue.mixin(MethodsOnly);

        vue.mixin(
            vue.extend<{ overlayVisible: boolean, currentOverlayComponent: Vue | null }, {
                showElement: (el: HTMLElement) => void,
                showComponent: (vm: Vue) => void,
                openOverlay: () => void,
                closeOverlay: () => void,
            }, {}, {}>({
                mounted() {
                    if (this.$parent == null) {
                        Object.assign(this.$data, {
                            overlayVisible: false,
                            currentOverlayComponent: null,
                        });
                        this.showElement = (el: HTMLElement): void => {
                            this.currentOverlayComponent = null;
                            (this.$refs.overlay as HTMLElement).appendChild(el);
                            this.openOverlay();
                        };
                        this.showComponent = (vm: Vue): void => {
                            this.currentOverlayComponent = vm;
                            (this.$refs.overlay as HTMLElement).appendChild(vm.$el);
                            this.openOverlay();
                        };
                        this.openOverlay = (): void => {
                            (this.$refs.overlay as HTMLElement).classList.add('visible');
                            document.body.classList.add('overflow-hidden');
                        };
                        this.closeOverlay = (): void => {
                            (this.$refs.overlay as HTMLElement).classList.remove('visible');
                            document.body.classList.remove('overflow-hidden');
                            this.currentOverlayComponent!.$emit(OverlayEvents.Closed);
                            this.currentOverlayComponent = null;
                        };

                        const overlay = document.createElement('div');
                        overlay.classList.add('overlay');

                        overlay.addEventListener('click', (e) => {
                            if (overlay.isEqualNode(e.target as HTMLElement)) {
                                this.closeOverlay();
                            }
                        })
                        this.$el.appendChild(overlay);
                        Object.assign(this.$refs, {overlay});

                        this.$root.$on(OverlayEvents.ShowComponent, this.showComponent);
                        this.$root.$on(OverlayEvents.ShowElement, this.showElement);
                        this.$root.$on(OverlayEvents.Hide, this.closeOverlay);
                    }
                },
                beforeDestroy() {
                    if (this.$parent == null) {
                        this.$root.$off(OverlayEvents.ShowComponent, this.showComponent);
                        this.$root.$off(OverlayEvents.ShowElement, this.showElement);
                        this.$root.$off(OverlayEvents.Hide, this.closeOverlay);
                    }
                },
            })
        );
    },
} as PluginObject<undefined>;