import {Dictionary, NavigationGuardNext, RawLocation, RouteRecord} from "vue-router/types/router";
import Router, {Route} from '@/app/Http/Router';

export type Middleware = (route: Route, router: Router, options?: string[]) => RawLocation | false | ((vm: any) => any) | void;
export type MiddlewareGroups = { [key: string]: Middleware[] }
export type KernelConstructor = new(router: Router) => Kernel;
export type ResolvableMiddleware = Middleware | string

export default class Kernel {
    protected defaultMiddleware: ResolvableMiddleware[] = [];
    protected groupAliases: MiddlewareGroups = {};
    protected aliases: Dictionary<Middleware> = {};

    private readonly _router: Router;

    constructor(router: Router) {
        this._router = router;
    }

    public handle(route: Route, next: NavigationGuardNext): void {
        const middleware = this.resolveMiddlewareList([
            ...this.defaultMiddleware,
        ]);
        route.matched.forEach((r: RouteRecord) => {
            middleware.push(...this.resolveMiddlewareList(r.meta.middleware || []));
        });

        for (let m of middleware) {
            const returned = m(route, this._router);

            if (returned != null) {
                // @ts-ignore
                next(returned);
                return;
            }
        }

        next();
    }

    public addDefaultMiddleware(middleware: Middleware | string) {
        this.defaultMiddleware.push(middleware);
    }

    public addGroupMiddlewareAlias(name: string, group: Middleware[]) {
        this.groupAliases[name] = group;
    }

    public addMiddlewareAlias(name: string, middleware: Middleware) {
        this.aliases[name] = middleware;
    }

    private resolveMiddlewareList(middleware: ResolvableMiddleware[]): Middleware[] {
        if (middleware != null) {
            // @ts-ignore
            return middleware.flatMap((middleware: ResolvableMiddleware): Middleware | Middleware[] => {
                if (typeof middleware === 'function') {
                    return middleware;
                }

                let optionString = '';
                let options: string[] = [];

                if (middleware.includes(':')) {
                    [middleware, optionString] = middleware.split(':');

                    options = optionString.split(',');
                }

                if (this.groupAliases.hasOwnProperty(middleware)) {
                    return this.groupAliases[middleware];
                }

                if (this.aliases.hasOwnProperty(middleware)) {
                    return (route: Route, router: Router) => {
                        return this.aliases[middleware as string](route, router, options);
                    };
                }

                throw new Error(`The middleware "${middleware}" could not be resolved to a valid middleware instance`);
            });
        }

        return [];
    }
}
